<template>
  <v-dialog persistent v-model="dialog" max-width="400">
    <v-card
      class="add-powerball-card elevation-0"
      style="border: 3px solid #fff !important"
    >
      <div class="pa-4 pa-sm-6">
        <div
          class="text-uppercase text-center mb-4"
          style="
            color: #f8d73c;
            font-size: 28px;
            font-weight: 800;
            letter-spacing: 1px;
            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
          "
        >
          Add Power Ball
        </div>
        <!-- <div class="d-flex justify-center mb-4 mb-sm-6">
          <div
            class="white--text rounded-lg pa-4"
            style="background-color: #08569e !important"
          >
            Note: Additional worth 50
          </div>
        </div> -->

        <v-alert color="blue" class="white--text mb-4">
          <div v-if="!parseInt(data.power_ball_count)">
            <strong>Note:</strong>

            <ul class="mb-0">
              <li>
                {{
                  `First power ball worth ${money_format(
                    parseFloat(data.rambol_1st_bet)
                  )} token`
                }}
              </li>
            </ul>
          </div>

          <div v-if="parseInt(data.power_ball_count)">
            <strong>Note:</strong>

            <ul class="mb-0">
              <!-- <li>
                {{
                  `Straight 2nd Power ball worth ${money_format(
                    parseFloat(data.straight_2nd_bet)
                  )}`
                }}
              </li> -->
              <li>
                {{
                  `2nd Power ball worth ${money_format(
                    parseFloat(data.rambol_2nd_bet)
                  )} token`
                }}
              </li>
            </ul>
          </div>
        </v-alert>

        <v-form
          ref="form"
          @submit.prevent="add_power_ball"
          class="d-flex flex-column"
          style="gap: 1rem"
        >
          <div class="d-flex bs-gap-3">
            <v-text-field
              autofocus
              v-model="combinations"
              dense
              label="Power Ball"
              :placeholder="`eg.  ${get_ramdom_number()}-${get_ramdom_number()}`"
              solo
              maxlength="3"
              hide-details="auto"
              @keyup="format_combination"
            ></v-text-field>

            <button class="powerball-button" @click.prevent="do_power_pick">
              <div class="content-wrapper">Power Pick</div>
            </button>
          </div>

          <div
            class="d-flex flex-column flex-sm-row justify-space-between"
            style="gap: 1rem"
          >
            <div
              class="d-flex flex-column-reverse flex justify-sm-space-between bs-gap-2 bs-gap-sm-3"
            >
              <button
                class="powerball-button reset"
                @click.prevent="close_dialog"
              >
                <div class="content-wrapper">Cancel</div>
              </button>

              <button
                class="powerball-button success"
                type="submit"
                :disabled="combinations.length != 3"
              >
                <div class="content-wrapper">Add</div>
              </button>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import formRules from "@/helpers/formRules";

export default {
  props: {
    data: {
      type: Object,
    },
  },

  data: () => ({
    // rules: formRules,
    combinations: "",
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },
  },

  methods: {
    do_power_pick() {
      this.combinations = `${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
    },

    get_ramdom_number() {
      return Math.floor(Math.random() * 10);
    },

    // is_valid_combination() {
    //   return this.combinations.length == 2 ? true : "Invalid combination";
    // },

    add_power_ball() {
      if (this.$refs.form.validate()) {
        this.$emit("set_close_power_ball_dialog", this.combinations);
      }
    },

    format_combination() {
      this.combinations = this.combinations.replace(/[^\d.-]/g, "");

      if (this.combinations.length == 2) {
        if (!this.combinations.includes("-")) {
          const combination_array = this.combinations.split("");
          this.combinations = `${combination_array[0]}-${combination_array[1]}`;
        }
      }
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    close_dialog() {
      this.$emit("set_close_power_ball_dialog", "");
    },
  },
};
</script>

<style>
.add-powerball-card {
  background: url("../../../assets/img/powerball/powerball-card-bet-bg.webp")
    no-repeat center;
  background-size: cover;
  border-radius: 1rem !important;
}
</style>
