<template>
  <div class="d-flex flex-column" style="gap: 0.3rem">
    <div class="jackpot-prize white--text text-center mb-5">
      <div class="matchup-schedule">Jackpot Prize</div>
      <h1 class="secondary--text d-flex align-center justify-center bs-gap-2">
        {{ money_format(parseFloat(data.prize)) }}
      </h1>
    </div>
    <div class="d-flex justify-center text-center bs-gap-3 bs-gap-lg-5">
      <div
        class="d-flex flex-column align-center justify-center text-center bs-gap-3"
      >
        <v-avatar class="team-avatar pa-2">
          <v-img :src="data.team_a_logo" :alt="data.team_a_name" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <div
          class="d-flex align-center justify-center"
          style="position: relative; min-height: 40px"
        >
          <span class="team-name">
            {{ data.team_a_name }}
            <br />
            {{ data.team_a_alias }}
          </span>
        </div>
      </div>

      <div class="d-flex flex-column align-center justify-cemter bs-gap-3 mt-6">
        <span class="matchup-schedule white--text" style="">{{
          game_time_format(data.schedule)
        }}</span>
        <h1
          style="
            -webkit-text-fill-color: #dc3e35;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #fff;
            font-family: 'Poppins', sans-serif;
            font-size: 50px;
            font-weight: 900 !important;
            font-style: italic;
            letter-spacing: -4px;
            line-height: 0.8;
            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
          "
        >
          VS
        </h1>
      </div>

      <div
        class="d-flex flex-column align-center justify-center text-center bs-gap-3"
      >
        <v-avatar class="team-avatar pa-2">
          <v-img :src="data.team_b_logo" :alt="data.team_b_name" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <div
          class="d-flex align-center justify-center"
          style="position: relative; min-height: 40px"
        >
          <span class="team-name">
            {{ data.team_b_name }}
            <br />
            {{ data.team_b_alias }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";

export default {
  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    game_time_format(date) {
      return formatDate(date, "format", "eee, MMM dd 'at' hh:mm a");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sigmar&display=swap");

.jackpot-prize h1 {
  font-family: "Sigmar", cursive;
  font-size: 50px;
  line-height: 1.2;
}

.team-avatar {
  width: 125px !important;
  height: 125px !important;
  background-color: #25b1ff !important;
  border: 5px solid #f8d73c !important;
  border-radius: 1rem !important;
}

.matchup-schedule {
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
}

.team-name {
  font-size: 16px;
  position: absolute;
  white-space: nowrap;
  line-height: 1.25;
  color: #f8d73c;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  text-transform: uppercase;
}

@media (width < 768px) {
  .team-avatar {
    width: 100px !important;
    height: 100px !important;
  }

  .matchup-schedule {
    font-size: 14px;
  }

  .team-name {
    font-size: 14px;
  }
}
</style>
