<template>
  <v-dialog persistent v-model="dialog" max-width="380">
    <v-card :loading="bet_loading" class="powerball-bet-confirm-card bs-shadow">
      <div class="pa-4 pa-sm-6">
        <div
          class="text-uppercase text-center mb-4"
          style="
            color: #f8d73c;
            font-size: 28px;
            font-weight: 800;
            letter-spacing: 1px;
            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
          "
        >
          Confirm
        </div>
        <div class="d-flex justify-center mb-4">
          <div
            class="white--text rounded-lg pa-4"
            style="background-color: #08569e !important"
          >
            You're about to place
            <span class="success--text">
              {{ moneyFormat(parseFloat(bet_data.total_amount)) }}
            </span>
            , please confirm to proceed.
          </div>
        </div>
        <v-alert
          v-if="alert_dialog"
          :icon="alert_type == 'warning' ? 'mdi-alert-box' : undefined"
          :type="alert_type"
          class="mb-3"
        >
          {{ alert_message }}
        </v-alert>

        <div v-if="appData && !appDataLoading">
          <input
            class="powerball-input"
            type="text"
            autofocus
            v-model="name"
            placeholder="Enter Name (optional)"
            v-if="
              (appData && appData.account_details.agent_id) ||
              (appData &&
                appData.account_details.investor_sub_agent_player_id &&
                appData.account_details.investor_sub_type_id != 2 &&
                appData.account_details.investor_type_id != 1)
            "
          />
        </div>

        <div class="mt-4 mt-sm-6">
          <div class="white--text mb-2">Combinations</div>
          <div class="d-flex" style="gap: 0.75rem">
            <div class="coin-box">
              <div class="coin-box-wrapper">
                {{ bet_data.combination1 }}
              </div>
            </div>

            <div class="coin-box">
              <div class="coin-box-wrapper">
                {{ bet_data.combination2 }}
              </div>
            </div>

            <div class="coin-box">
              <div class="coin-box-wrapper">
                {{ bet_data.combination3 }}
              </div>
            </div>

            <div class="coin-box">
              <div class="coin-box-wrapper">
                {{ bet_data.combination4 }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="Object.keys(bet_data.power_balls).length"
          class="d-flex flex-column mt-4"
        >
          <div class="white--text mb-2">Powerball</div>

          <div class="d-flex" style="gap: 0.75rem">
            <div
              class="coin-box"
              v-for="(power_ball, index) in bet_data.power_balls"
              :key="index"
            >
              <div class="coin-box-wrapper">
                {{ power_ball.combination }}
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-end bs-gap-2 mt-6">
          <v-btn
            text
            class="px-2 mr-1"
            @click="close_dialog"
            :disabled="bet_loading"
          >
            Cancel
          </v-btn>

          <button
            class="powerball-button"
            @click="doBet"
            :disabled="bet_loading || alert_type == 'success'"
          >
            <div class="content-wrapper">Confirm</div>
          </button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formRules from "@/helpers/formRules";
import responsePost from "@/helpers/api_request_post";

export default {
  props: {
    bet_data: {
      type: Object,
    },
  },

  data: () => ({
    array_combinations: [],
    array_power_balls: [],
    rules: formRules,
    name: "",
    bet_status: false,
    bet_loading: false,
    alert_dialog: false,
    alert_message: "",
    alert_type: "",
    bet_information: {},
    power_balls: "",
  }),

  computed: {
    ...mapState(["appData", "appDataLoading", "actualPoints"]),

    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },
  },

  mounted() {
    this.array_combinations.push({
      combination: this.bet_data.combination1,
      quarter: 1,
    });
    this.array_combinations.push({
      combination: this.bet_data.combination2,
      quarter: 2,
    });
    this.array_combinations.push({
      combination: this.bet_data.combination3,
      quarter: 3,
    });
    this.array_combinations.push({
      combination: this.bet_data.combination4,
      quarter: 4,
    });
    if (this.bet_data.power_balls.length) {
      this.bet_data.power_balls.forEach((element) => {
        this.array_power_balls.push({ combination: element.combination });
        this.power_balls = this.power_balls.concat(element.combination, ", ");
      });
    }
  },

  methods: {
    ...mapActions("logout", ["doLogout"]),

    async doBet() {
      this.bet_loading = !this.bet_loading;

      const response = await responsePost(
        this.set_form_data(),
        "power_ball/bet"
      );
      this.alert_dialog = true;

      switch (response.status) {
        case 200:
          {
            this.$store.commit(
              "setActualPoints",
              parseFloat(this.actualPoints) -
                parseFloat(this.bet_data.total_amount)
            );

            const sound = new Audio(require("@/assets/audio/bet.wav"));
            sound.play();
            this.bet_status = true;
            this.alert_type = "success";
            this.alert_message = "Success.";
          }
          break;
        case 401:
          await this.doLogout();
          this.$router.push({ name: "Home", query: { is_force: 1 } });
          break;
        case 403:
          this.alert_type = "error";
          this.alert_message = response.data.messages.game;
          break;
        case 409:
          this.alert_type = "warning";
          if (response.data.messages.bet) {
            this.alert_message = response.data.messages.bet;
          } else {
            this.alert_message = response.data.messages.combination;
          }
          break;
        default:
          this.alert_type = "error";
          this.alert_message = "Something went wrong.";
          break;
      }

      this.bet_loading = !this.bet_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("game_id", this.bet_data.game_id);
      form_data.append("bet_type_id", this.bet_data.bet_type_id);
      form_data.append("name", this.name);
      form_data.append("combinations", JSON.stringify(this.array_combinations));
      form_data.append("power_balls", JSON.stringify(this.array_power_balls));

      return form_data;
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    close_dialog() {
      // this.$emit("set_close_bet_confirm_dialog", this.bet_status, {});
      this.$emit("set_close_bet_confirm_dialog", this.bet_status);
    },
  },

  watch: {
    alert_type(val) {
      if (val == "success") {
        setTimeout(() => {
          // const newDate = new Date();
          // this.bet_information = {
          //   type: this.bet_type_id == 1 ? "Straight" : "Rambol",
          //   combinations: `${this.bet_data.combination1}, ${this.bet_data.combination2}, ${this.bet_data.combination3}, ${this.bet_data.combination4}`,
          //   power_balls: this.power_balls.length
          //     ? this.power_balls.replace(/,\s*$/, "")
          //     : "-",
          //   total_bet_amount: this.bet_data.total_amount,
          //   created_at: newDate,
          // };

          this.$emit(
            "set_close_bet_confirm_dialog",
            this.bet_status
            // this.bet_information
          );
        }, 1500);
      }
    },
  },
};
</script>

<style>
.powerball-bet-confirm-card {
  background: url("../../../assets/img/powerball/powerball-card-bet-bg.webp")
    no-repeat center;
  background-size: cover;
  border-radius: 1rem !important;
}

.coin-box {
  width: 50px;
  height: 50px;
  padding: 3px;
  white-space: nowrap;
  background: #6d3e2a;
  background: linear-gradient(15deg, #6d3e2a 0%, #f9d75c 100%);
  box-shadow: 0 0 0.75rem 0.25rem rgba(176, 221, 255, 0.5);
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.coin-box .coin-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(50px - 3px - 3px);
  padding: 0 1rem;
  font-weight: 800;
  border-radius: inherit;
  color: #372905;
  background: #faad2d;
  background: linear-gradient(90deg, #faad2d 0%, #a9721b 100%);
  text-transform: uppercase;
}

.powerball-button {
  padding: 3px;
  white-space: nowrap;
  background: #117eb9;
  background: linear-gradient(15deg, #117eb9 0%, #c6e9ff 100%);
  box-shadow: 0 0 0.75rem 0.25rem rgba(176, 221, 255, 0.5);
  border-radius: 0.5rem;
  position: relative;
  z-index: 1;
}
.powerball-button .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  font-weight: 600;
  border-radius: 0.35rem;
  color: #fff;
  background: #2ea9f9;
  background: linear-gradient(15deg, #2ea9f9 0%, #95d4ff 100%);
  text-transform: uppercase;
}
.powerball-button:disabled {
  opacity: 0.5;
}
</style>
