var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"380"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"powerball-bet-confirm-card bs-shadow",attrs:{"loading":_vm.bet_loading}},[_c('div',{staticClass:"pa-4 pa-sm-6"},[_c('div',{staticClass:"text-uppercase text-center mb-4",staticStyle:{"color":"#f8d73c","font-size":"28px","font-weight":"800","letter-spacing":"1px","text-shadow":"2px 2px 1px rgba(0, 0, 0, 0.75)"}},[_vm._v(" Confirm ")]),_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('div',{staticClass:"white--text rounded-lg pa-4",staticStyle:{"background-color":"#08569e !important"}},[_vm._v(" You're about to place "),_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.moneyFormat(parseFloat(_vm.bet_data.total_amount)))+" ")]),_vm._v(" , please confirm to proceed. ")])]),(_vm.alert_dialog)?_c('v-alert',{staticClass:"mb-3",attrs:{"icon":_vm.alert_type == 'warning' ? 'mdi-alert-box' : undefined,"type":_vm.alert_type}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]):_vm._e(),(_vm.appData && !_vm.appDataLoading)?_c('div',[(
            (_vm.appData && _vm.appData.account_details.agent_id) ||
            (_vm.appData &&
              _vm.appData.account_details.investor_sub_agent_player_id &&
              _vm.appData.account_details.investor_sub_type_id != 2 &&
              _vm.appData.account_details.investor_type_id != 1)
          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"powerball-input",attrs:{"type":"text","autofocus":"","placeholder":"Enter Name (optional)"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-4 mt-sm-6"},[_c('div',{staticClass:"white--text mb-2"},[_vm._v("Combinations")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.75rem"}},[_c('div',{staticClass:"coin-box"},[_c('div',{staticClass:"coin-box-wrapper"},[_vm._v(" "+_vm._s(_vm.bet_data.combination1)+" ")])]),_c('div',{staticClass:"coin-box"},[_c('div',{staticClass:"coin-box-wrapper"},[_vm._v(" "+_vm._s(_vm.bet_data.combination2)+" ")])]),_c('div',{staticClass:"coin-box"},[_c('div',{staticClass:"coin-box-wrapper"},[_vm._v(" "+_vm._s(_vm.bet_data.combination3)+" ")])]),_c('div',{staticClass:"coin-box"},[_c('div',{staticClass:"coin-box-wrapper"},[_vm._v(" "+_vm._s(_vm.bet_data.combination4)+" ")])])])]),(Object.keys(_vm.bet_data.power_balls).length)?_c('div',{staticClass:"d-flex flex-column mt-4"},[_c('div',{staticClass:"white--text mb-2"},[_vm._v("Powerball")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.75rem"}},_vm._l((_vm.bet_data.power_balls),function(power_ball,index){return _c('div',{key:index,staticClass:"coin-box"},[_c('div',{staticClass:"coin-box-wrapper"},[_vm._v(" "+_vm._s(power_ball.combination)+" ")])])}),0)]):_vm._e(),_c('div',{staticClass:"d-flex justify-end bs-gap-2 mt-6"},[_c('v-btn',{staticClass:"px-2 mr-1",attrs:{"text":"","disabled":_vm.bet_loading},on:{"click":_vm.close_dialog}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"powerball-button",attrs:{"disabled":_vm.bet_loading || _vm.alert_type == 'success'},on:{"click":_vm.doBet}},[_c('div',{staticClass:"content-wrapper"},[_vm._v("Confirm")])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }