<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10" style="border: none !important">
      <div class="pa-4 pa-sm-6">
        <div class="d-flex" style="float: right">
          <v-dialog
            ref="filter"
            v-model="filter"
            :return-value.sync="date"
            width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                :color="filter ? 'primary' : undefined"
                icon
                v-bind="attrs"
                v-on="on"
                dark
              >
                <v-icon>mdi-calendar-clock</v-icon>
              </v-btn>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text @click="filter = false"> Cancel </v-btn>
              <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                :to="{ name: 'Tutorial' }"
                dark
              >
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>How to bet</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                :to="{ name: 'MyBets' }"
                dark
              >
                <v-icon>mdi-clipboard-text-outline</v-icon>
              </v-btn>
            </template>
            <span>My Bets</span>
          </v-tooltip>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex align-center bs-gap-2">
            <h1 class="text-uppercase font-weight-bold white--text">
              Power Ball
            </h1>
          </div>
        </div>
      </div>
    </v-card>

    <div v-if="!recordsLoading">
      <div v-if="records.length">
        <div
          class="d-flex flex-column mb-2 pa-0 py-md-8"
          style="gap: 1rem"
          v-for="(details, index) in records"
          :key="index"
        >
          <div class="bs-row bs-g-3 d-flex justify-center mb-5 mb-md-0">
            <div class="bs-col-md-8">
              <v-card class="elevation-10 powerball-bet-card">
                <div class="d-flex flex-column bs-gap-4 pa-6 pa-md-8">
                  <GameDetails v-if="records" :data="details" />

                  <div class="text-center" v-if="details.is_started">
                    <div class="mb-4">Result</div>

                    <div
                      class="powerball-table bs-border bs-overflow-hidden rounded-lg"
                    >
                      <v-simple-table dense style="pointer-events: none">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center px-4">1st</th>
                              <th class="text-center px-4">2nd</th>
                              <th class="text-center px-4">3rd</th>
                              <th class="text-center px-4">4th</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class="font-weight-bold px-4">
                                {{
                                  details.results[0]
                                    ? details.results[0].combination_number
                                    : "-"
                                }}
                              </td>
                              <td class="font-weight-bold px-4">
                                {{
                                  details.results[1]
                                    ? details.results[1].combination_number
                                    : "-"
                                }}
                              </td>
                              <td class="font-weight-bold px-4">
                                {{
                                  details.results[2]
                                    ? details.results[2].combination_number
                                    : "-"
                                }}
                              </td>
                              <td class="font-weight-bold px-4">
                                {{
                                  details.results[3]
                                    ? details.results[3].combination_number
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </div>
                  <GameBet
                    v-if="details.game_status_id == null"
                    :data="{
                      game_id: details.game_id,
                      game_index: index,
                      settings: details.settings,
                    }"
                  />
                  <!-- @add_bets="add_bets" -->

                  <div
                    v-else-if="
                      details.game_status_id == 1 ||
                      details.game_status_id == 2 ||
                      details.game_status_id == 3 ||
                      details.game_status_id == 4
                    "
                    class="card d-flex flex-column align-center bs-gap-2 text-center"
                  >
                    <div class="d-flex" style="gap: 5px">
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        class="fa-spin"
                      />
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        class="fa-spin"
                        style="transform: rotate(45deg)"
                      />
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        class="fa-spin"
                        style="transform: rotate(90deg)"
                      />
                    </div>
                    The game has started, betting are now closed.
                  </div>

                  <div
                    v-else-if="details.game_status_id == 5"
                    class="card d-flex flex-column align-center bs-gap-2 text-center"
                  >
                    <div class="d-flex" style="gap: 5px">
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        style="filter: grayscale(100%)"
                      />
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        style="filter: grayscale(100%)"
                      />
                      <img
                        src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                        height="16"
                        style="filter: grayscale(100%)"
                      />
                    </div>
                    The game has ended.
                  </div>
                </div>
              </v-card>
            </div>

            <!-- <div class="bs-col-md-4">
              <MyBets :bets="details.bets" />
            </div> -->
          </div>
        </div>
      </div>

      <div class="bs-col-md-12 bs-col-xl-12 mx-auto mt-8" v-else>
        <v-card
          class="card d-flex flex-column align-center bs-gap-2 text-center pa-4 pa-sm-6"
        >
          <div class="d-flex justify-center bs-gap-2">
            <img
              width="32"
              height="32"
              src="https://img.icons8.com/fluency/48/nothing-found.png"
              alt="nothing-found"
            />
          </div>
          Sorry, but there's no scheduled game today.
        </v-card>
      </div>
    </div>

    <div v-else>
      <v-row class="mt-5 d-flex justify-center">
        <v-col cols="12" md="8">
          <GamesLoading />
        </v-col>

        <v-col cols="12" md="8">
          <GamesLoading />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GameDetails from "@/components/app/power_ball/GameDetails";
import GamesLoading from "@/components/app/power_ball/GamesLoading";
import GameBet from "@/components/app/power_ball/GameBet";
// import MyBets from "@/components/app/power_ball/MyBets";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    GameDetails,
    GameBet,
    GamesLoading,
    // MyBets,
  },

  data: () => ({
    filter: false,
    date: "",
  }),

  created() {
    this.loadRecords();
  },

  computed: {
    ...mapState("power_ball_games", ["records", "recordsLoading"]),
  },

  methods: {
    ...mapActions("power_ball_games", ["getRecords"]),

    async loadRecords() {
      this.filter = false;

      if (!this.recordsLoading) {
        this.$store.commit("power_ball_games/setRecords", []);

        const formData = new FormData();
        formData.append("scheduled_date_start", this.date);
        formData.append("scheduled_date_end", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },

    filterDate() {
      this.$refs.filter.save(this.date);
      this.loadRecords();
    },

    // add_bets(game_index, bet_information) {
    //   this.records[game_index].bets = [
    //     bet_information,
    //     ...this.records[game_index].bets,
    //   ];
    // },
  },
};
</script>

<style>
.powerball-bet-card {
  background: url("../../../assets/img/powerball/powerball-card-bet-bg.webp")
    no-repeat center;
  background-size: cover;
  border-radius: 1rem !important;
}

.powerball-table {
  background: #117eb9;
}

.powerball-button {
  padding: 3px;
  white-space: nowrap;
  background: #117eb9;
  background: linear-gradient(15deg, #117eb9 0%, #c6e9ff 100%);
  box-shadow: 0 0 0.75rem 0.25rem rgba(176, 221, 255, 0.5);
  border-radius: 0.5rem;
  position: relative;
  z-index: 1;
  height: 42.42px;
}

.powerball-button.reset {
  background: #6e0a0a;
  background: linear-gradient(15deg, #6e0a0a 0%, #ffa8a8 100%);
}

.powerball-button.success {
  background: #075423;
  background: linear-gradient(15deg, #075423 0%, #bfffd6 100%);
}

.powerball-button .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  font-weight: 600;
  border-radius: 0.35rem;
  color: #fff;
  background: #2ea9f9;
  background: linear-gradient(15deg, #2ea9f9 0%, #95d4ff 100%);
  text-transform: uppercase;
}

.powerball-button.reset .content-wrapper {
  background: #e62121;
  background: linear-gradient(15deg, #e62121 0%, #ea7575 100%);
}

.powerball-button.success .content-wrapper {
  background: #18861c;
  background: linear-gradient(15deg, #18861c 0%, #84e387 100%);
}

.powerball-button:disabled {
  opacity: 0.5;
}
</style>
