<template>
  <div class="rounded animate__animated animate__flipInX">
    <div class="d-flex flex-column align-center" style="gap: 1rem">
      <div
        class="d-flex flex-column flex-sm-row"
        :style="$vuetify.breakpoint.mdAndUp ? '' : 'gap: 0.5rem'"
      >
        <div class="d-flex align-center">
          <div class="d-flex align-center rounded-lg">
            <div class="d-flex flex-column">
              <div class="d-flex flex-sm-row flex-column" style="gap: 1rem">
                <div class="bs-row bs-g-2">
                  <div class="bs-col-6 bs-col-md-3">
                    <input
                      class="powerball-input"
                      type="text"
                      autofocus
                      v-model="combination1"
                      ref="com1"
                      :placeholder="eg1"
                      maxlength="3"
                      @keyup="text_focus(1)"
                    />
                  </div>
                  <div class="bs-col-6 bs-col-md-3">
                    <input
                      class="powerball-input"
                      type="text"
                      autofocus
                      v-model="combination2"
                      ref="com2"
                      :placeholder="eg2"
                      maxlength="3"
                      @keyup="text_focus(2)"
                    />
                  </div>
                  <div class="bs-col-6 bs-col-md-3">
                    <input
                      class="powerball-input"
                      type="text"
                      autofocus
                      v-model="combination3"
                      ref="com3"
                      :placeholder="eg3"
                      maxlength="3"
                      @keyup="text_focus(3)"
                    />
                  </div>
                  <div class="bs-col-6 bs-col-md-3">
                    <input
                      class="powerball-input"
                      type="text"
                      autofocus
                      v-model="combination4"
                      ref="com4"
                      :placeholder="eg4"
                      maxlength="3"
                      @keyup="text_focus(4)"
                    />
                  </div>
                </div>
                <button class="powerball-button" @click="do_power_pick">
                  <div class="content-wrapper">Power Pick</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%" v-if="power_balls && power_balls.length != 0">
        <div class="d-flex bs-gap-2">
          <v-chip
            close
            color="green"
            label
            v-for="(power_ball, index) in power_balls"
            :key="index"
            @click:close="
              remove_power_ball(
                power_ball.straight_amount,
                power_ball.rambol_amount
              )
            "
          >
            {{ power_ball.combination }}
          </v-chip>
        </div>
      </div>

      <div style="width: 100%">
        <div
          class="d-flex flex-column flex-sm-row justify-sm-space-between bs-gap-2 bs-gap-sm-3"
        >
          <button
            class="powerball-button reset rounded-pill"
            :disabled="
              !combination1 && !combination2 && !combination3 && !combination4
            "
            @click="reset_combinations"
          >
            <div class="content-wrapper rounded-pill">Reset</div>
          </button>

          <div class="d-flex flex-column flex-sm-row bs-gap-2 bs-gap-sm-3">
            <button
              class="powerball-button rounded-pill"
              @click="set_power_ball_dialog"
              :disabled="
                combination1.length != 3 ||
                combination2.length != 3 ||
                combination3.length != 3 ||
                combination4.length != 3 ||
                power_balls.length == 2
              "
            >
              <div class="content-wrapper rounded-pill">Add Power Ball</div>
            </button>
            <button
              class="powerball-button success rounded-pill"
              :disabled="
                combination1.length != 3 ||
                combination2.length != 3 ||
                combination3.length != 3 ||
                combination4.length != 3
              "
              @click="set_bet_confirm_dialog(2)"
            >
              <div class="content-wrapper rounded-pill">
                {{ money_format(parseFloat(rambol_total_bet_amount)) }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <AddDialog
      v-if="power_ball_dialog"
      :data="power_ball_dialog_data"
      @set_close_power_ball_dialog="set_close_power_ball_dialog"
    />

    <BetConfirm
      v-if="bet_confirm_dialog"
      :bet_data="bet_data"
      @set_close_bet_confirm_dialog="set_close_bet_confirm_dialog"
    />
  </div>
</template>

<script>
import AddDialog from "@/components/app/power_ball/PowerBall";
import BetConfirm from "@/components/app/power_ball/BetConfirm";

export default {
  components: {
    AddDialog,
    BetConfirm,
  },

  props: {
    data: {
      type: Object,
    },
  },

  data: () => ({
    combination1: "",
    combination2: "",
    combination3: "",
    combination4: "",
    combinations: "",
    straight_total_bet_amount: 0,
    rambol_total_bet_amount: 0,
    straight_bet_amount: 0,
    rambol_bet_amount: 0,
    power_balls: [],
    power_ball_dialog: false,
    power_ball_dialog_data: [],
    bet_confirm_dialog: false,
    bet_data: {},
    eg1: null,
    eg2: null,
    eg3: null,
    eg4: null,
  }),

  mounted() {
    this.straight_bet_amount = this.get_setting(2);
    this.rambol_bet_amount = this.get_setting(3);
    this.straight_total_bet_amount = this.straight_bet_amount;
    this.rambol_total_bet_amount = this.rambol_bet_amount;
    this.bets = this.data.bets;

    this.eg1 = `e.g. ${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
    this.eg2 = `e.g. ${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
    this.eg3 = `e.g. ${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
    this.eg4 = `e.g. ${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
  },

  methods: {
    set_bet_confirm_dialog(bet_type_id) {
      this.bet_data = {
        game_id: this.data.game_id,
        bet_type_id: bet_type_id,
        combination1: this.combination1,
        combination2: this.combination2,
        combination3: this.combination3,
        combination4: this.combination4,
        power_balls: this.power_balls,
        total_amount:
          bet_type_id == 1
            ? this.straight_total_bet_amount
            : this.rambol_total_bet_amount,
      };

      this.bet_confirm_dialog = !this.bet_confirm_dialog;
    },

    // set_close_bet_confirm_dialog(status, bet_information) {
    //   if (status) {
    //     this.reset_combinations();
    //     this.$emit("add_bets", this.data.game_index, bet_information);
    //   }
    //   this.bet_confirm_dialog = !this.bet_confirm_dialog;
    // },

    set_close_bet_confirm_dialog(status) {
      if (status) {
        this.reset_combinations();
      }
      this.bet_confirm_dialog = !this.bet_confirm_dialog;
    },

    do_power_pick() {
      this.combination1 = `${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
      this.combination2 = `${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
      this.combination3 = `${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
      this.combination4 = `${this.get_ramdom_number()}-${this.get_ramdom_number()}`;
    },

    get_ramdom_number() {
      return Math.floor(Math.random() * 10);
    },

    set_power_ball_dialog() {
      this.power_ball_dialog_data = {
        power_ball_count: this.power_balls.length,
        straight_1st_bet: this.get_setting(6),
        straight_2nd_bet: this.get_setting(7),
        rambol_1st_bet: this.get_setting(8),
        rambol_2nd_bet: this.get_setting(9),
      };

      this.power_ball_dialog = !this.power_ball_dialog;
    },

    set_close_power_ball_dialog(added_power_ball) {
      if (added_power_ball.length) {
        const straight_amount =
          this.power_balls.length == 1
            ? this.get_setting(7)
            : this.get_setting(6);
        const rambol_amount =
          this.power_balls.length == 1
            ? this.get_setting(9)
            : this.get_setting(8);

        this.power_balls.push({
          combination: added_power_ball,
          straight_amount: straight_amount,
          rambol_amount: rambol_amount,
        });

        this.straight_total_bet_amount =
          parseFloat(this.straight_total_bet_amount) +
          parseFloat(straight_amount);
        this.rambol_total_bet_amount =
          parseFloat(this.rambol_total_bet_amount) + parseFloat(rambol_amount);
      }

      this.power_ball_dialog = !this.power_ball_dialog;
    },

    remove_power_ball(straight_amount, rambol_amount) {
      this.power_balls = this.power_balls.filter(
        (power_ball) =>
          power_ball.straight_amount != straight_amount &&
          power_ball.rambol_amount != rambol_amount
      );

      switch (this.power_balls.length) {
        case 0:
          this.straight_total_bet_amount = this.get_setting(2);
          this.rambol_total_bet_amount = this.get_setting(3);
          this.power_ball_bet_amount = 0;
          break;
        case 1:
          this.power_balls[0].straight_amount = this.get_setting(6);
          this.power_balls[0].rambol_amount = this.get_setting(8);
          this.straight_total_bet_amount =
            parseFloat(this.straight_total_bet_amount) -
            parseFloat(this.get_setting(7));
          this.rambol_total_bet_amount =
            parseFloat(this.rambol_total_bet_amount) -
            parseFloat(this.get_setting(9));
          break;
      }
    },

    get_setting(id) {
      let setting = "";

      this.data.settings.forEach((element) => {
        if (element.config_id == id) setting = element.value;
      });

      return setting;
    },

    text_focus(flag) {
      switch (flag) {
        case 1:
          this.combination1 = this.combination1.replace(/[^\d.-]/g, "");
          if (this.combination1.length == 2) {
            if (!this.combination1.includes("-")) {
              const combination_array = this.combination1.split("");
              this.combination1 = `${combination_array[0]}-${combination_array[1]}`;
              this.$refs.com2.focus();
            }
          }
          break;
        case 2:
          this.combination2 = this.combination2.replace(/[^\d.-]/g, "");
          if (this.combination2.length == 2) {
            if (!this.combination2.includes("-")) {
              const combination_array = this.combination2.split("");
              this.combination2 = `${combination_array[0]}-${combination_array[1]}`;
              this.$refs.com3.focus();
            }
          }
          break;
        case 3:
          this.combination3 = this.combination3.replace(/[^\d.-]/g, "");
          if (this.combination3.length == 2) {
            if (!this.combination3.includes("-")) {
              const combination_array = this.combination3.split("");
              this.combination3 = `${combination_array[0]}-${combination_array[1]}`;
              this.$refs.com4.focus();
            }
          }
          break;
        case 4:
          this.combination4 = this.combination4.replace(/[^\d.-]/g, "");
          if (this.combination4.length == 2) {
            if (!this.combination4.includes("-")) {
              const combination_array = this.combination4.split("");
              this.combination4 = `${combination_array[0]}-${combination_array[1]}`;
              this.$refs.com1.focus();
            }
          }
          break;
      }
    },

    reset_combinations() {
      this.combinations = "";
      this.combination1 = "";
      this.combination2 = "";
      this.combination3 = "";
      this.combination4 = "";
      this.power_balls = [];
      this.straight_bet_amount = this.get_setting(2);
      this.rambol_bet_amount = this.get_setting(3);
      this.straight_total_bet_amount = this.straight_bet_amount;
      this.rambol_total_bet_amount = this.rambol_bet_amount;
      this.$refs.com1.focus();
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style>
.powerball-input {
  min-width: 0;
  width: 100%;
  height: 42.43px;
  padding: 0 0.75rem;
  color: #fff;
  background-color: #1f95d5;
  border: 3px solid #59bfff;
  border-radius: 0.5rem;
  outline: none;
}
.powerball-input::placeholder {
  color: #fff;
}
</style>
