<template>
  <div
    class="main-content"
    style="min-height: unset !important; margin-bottom: unset !important"
  >
    <div class="powerball-cover pa-4 pa-sm-6 pa-md-8 pa-lg-10 pa-xl-16">
      <h1 class="text-uppercase mb-6 mt-14">Welcome to</h1>
      <img
        src="../../../assets/img/powerball/powerball.webp"
        class="powerball-img d-block mb-2 mb-sm-4"
      />
      <div class="white--text me-16 me-sm-0" style="font-size: 20px">
        Experience the ultimate lotto-style gaming
      </div>
      <div class="card-container mt-4 mt-sm-6 mt-md-8 mt-lg-10">
        <div class="bs-row bs-g-3">
          <div class="bs-col-6 bs-col-lg-3">
            <v-card
              class="card elevation-10"
              @click="redirectToPowerballBetting"
            >
              <img
                src="../../../assets/img/powerball/play-now.jpg"
                alt="..."
                style="width: 100%; height: auto"
                class="d-block"
              />
            </v-card>
          </div>
          <div class="bs-col-6 bs-col-lg-3">
            <v-card class="card elevation-10">
              <img
                src="../../../assets/img/powerball/ads-1.jpg"
                alt="..."
                style="width: 100%; height: auto"
                class="d-block"
              />
            </v-card>
          </div>
          <div class="bs-col-6 bs-col-lg-3">
            <v-card class="card elevation-10">
              <img
                src="../../../assets/img/powerball/ads-2.jpg"
                alt="..."
                style="width: 100%; height: auto"
                class="d-block"
              />
            </v-card>
          </div>
          <div class="bs-col-6 bs-col-lg-3">
            <v-card class="card elevation-10">
              <img
                src="../../../assets/img/powerball/ads-3.jpg"
                alt="..."
                style="width: 100%; height: auto"
                class="d-block"
              />
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToPowerballBetting() {
      this.$router.push({
        name: "PowerBallGames",
      });
    },
  },
};
</script>

<style scoped>
.powerball-cover {
  background: url("../../../assets/img/powerball/powerball-bg.jpg") no-repeat
    top;
  background-size: cover;
  margin-bottom: calc(230px + 40px);

  & h1 {
    color: #fff;
    font-size: 95px;
    font-weight: 600 !important;
    font-style: italic;
    line-height: 0.8;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
  }

  & .powerball-img {
    width: auto;
    height: 75px;
  }

  & .card-container {
    margin-bottom: calc(-230px + -40px);

    & .card {
      transition: ease 0.3s;
    }

    & .card:hover {
      transform: translateY(-10px);
    }
  }
}

@media (width < 1400px) {
  .powerball-img {
    width: auto;
    height: 55px;
  }
}

@media (width < 1200px) {
  .powerball-cover {
    margin-bottom: calc(100px + 40px);

    & h1 {
      font-size: 25px;
    }
    & .powerball-img {
      width: auto;
      height: 30px;
    }
    & .card-container {
      margin-bottom: calc(-100px + -40px);
    }
  }
}

@media (width < 992px) {
  .powerball-cover {
    margin-bottom: 0;

    & .powerball-img {
      width: auto;
      height: 30px;
    }
    & .card-container {
      margin-bottom: 0;
    }
  }
}
</style>
